import { graphql } from "gatsby";
import { propDataTypes, blogDataDefaults } from "../../../prop-types";
import SupportDoc from "../../../layouts/WithSideBarPage/_supportDocPage";
import { imageManager } from "../../../components/Image";

const UsefulExcelLinks = ({ data }) => (
  <SupportDoc
    withComments={false}
    frontmatter={data.markdownRemark.frontmatter}
    htmlAst={data.markdownRemark.htmlAst}
    afterAccentTitleToolkit={false}
    imageRenderer={imageManager(data.pageImages)}
  />
);

UsefulExcelLinks.propTypes = propDataTypes;
UsefulExcelLinks.defaultProps = blogDataDefaults;

export default UsefulExcelLinks;

export const query = graphql`
  query getUsefulExcelLinks(
    $path: String!
    $siteLanguage: String!
  ) {
    markdownRemark(frontmatter: { path: { eq: $path }, language: { eq: $siteLanguage } }) {
      htmlAst
      frontmatter {
        seo {
          description
          link
          title
        }
        path
        bannerTitle
        bannerSubTitle
        sidebarTitle
        href
        featureToolkit
        featureTitleToolkit
        featureButtonTextToolkit
      }
    }
    pageImages:allFile(
      filter: {
        relativePath: {
          glob: "images/support/useful-excel-links/**/*"
        },
        extension: {
          in: ["png", "jpg", "jpeg"]
        }
      }
    )
    {
      nodes {
        ...stdFluidImage
      }
    }
  }
`;
